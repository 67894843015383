export const mainMenuOptions = [
    {
        name: "Inicio",
        link: "/",
    },
    {
        name: "Portafolio General",
        link: "/products/PRV",
    },
    {
        name: "PQRS",
        link: "/pqrs",
    },
    {
        name: "Política de privacidad",
        link: "/privacy-policy",
    },
    {
        name: "Contactenos",
        link: "/contact-us",
    },
];

export const productsCategoriesOptions = [
    {
        name: "Protección visual",
        link: "/products/PRV",
        category: "PRV",
    },
    {
        name: "Protección cabeza",
        link: "/products/PRC",
        category: "PRC",
    },
    {
        name: "Protección facial",
        link: "/products/PRF",
        category: "PRF",
    },
    {
        name: "Protección auditiva",
        link: "/products/PRA",
        category: "PRA",
    },
    {
        name: "Protección manual",
        link: "/products/PRM",
        category: "PRM",
    },
    {
        name: "Protección corporal",
        link: "/products/PCR",
        category: "PCR",
    },
    {
        name: "Protección anti-caída",
        link: "/products/PAC",
        category: "PAC",
    },
    {
        name: "Proteccion en calzado",
        link: "/products/CAS",
        category: "CAS",
    },
]
